@use 'common'
@use 'sass:math'

body
	--Global-color-text: #000

	--Button-height-primary: calc(50rem/16)
	--Button-background-primary: rgba(255,255,255,.2)
	--Button-borderColor-primary: #FFF
	--Button-hover-background-primary: #FFF
	--Button-hover-borderColor-primary: #DCDCDC
	--Button-text-color-primary: #FFF
	--Button-hover-text-color-primary: #000

	--Button-background-primary-hover-inverted: var(--Button-hover-borderColor-primary)

	--Button-height-secondary: calc(40rem/16)
	--Button-background-secondary: #c8102e
	--Button-borderColor-secondary: #c8102e
	--Button-hover-background-secondary: #FFF
	--Button-hover-borderColor-secondary: #000
	--Button-text-color-secondary: #FFF
	--Button-hover-text-color-secondary: #000

	--RestaurantTag-fontSize: calc(14rem/16)
	--RestaurantTag-padding: calc(5rem/16) calc(14rem/16)

	--Footer-background: #121212
	--Footer-column-padding: 16px 10px
	--Footer-spacing-vertical: .25rem
	--Footer-separator-color: #333333
	--Footer-separator-thickness: 1px

	--Modal-background: #FFF
	--Navigation-offset-mobile: 23px
	--Navigation-link-color-active: #dc102e
	--Navigation-link-border: 1px solid rgba(0, 0, 0, 0.15)

	--Banner-imageWidth: calc(500rem/16)
