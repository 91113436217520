@font-face
	font-family: 'GT America'
	src: url('/fonts/regular/GT-America-Standard-Regular.woff2') format('woff2'), url('/fonts/regular/GT-America-Standard-Regular.woff') format('woff')
	font-weight: 400
	font-style: regular

@font-face
	font-family: 'GT America'
	src: url('/fonts/medium/GT-America-Standard-Medium.woff2') format('woff2'), url('/fonts/medium/GT-America-Standard-Medium.woff') format('woff')
	font-weight: 500
	font-style: medium

@font-face
	font-family: 'GT America'
	src: url('/fonts/bold/GT-America-Standard-Bold.woff2') format('woff2'), url('/fonts/bold/GT-America-Standard-Bold.woff') format('woff')
	font-weight: 700
	font-style: bold
