@use 'common'

html,
body
	color: var(--Global-color-text)
	padding: 0
	margin: 0
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box
	font-family: GT America
